/* html {
  background: rgb(240, 240, 240) 100%;
}
body {
  background: radial-gradient(
    circle at center,
    rgb(255, 255, 255) 0,
    rgb(240, 240, 240) 100%
  );
} */

.btn-outline-dark {
  background-color: white;
}

.btn-outline-dark:hover {
  background-color: #212529;
}

.profile-container {
  margin-bottom: 15px;
}

/* Dark mode */
/* @media (prefers-color-scheme: dark) { */
html {
  background: #000;
}
body {
  background: radial-gradient(
    circle at center,
    rgb(30, 30, 30) 0,
    rgb(0, 0, 0) 100%
  );
  color: white;
}
.btn-outline-dark {
  background-color: transparent;
  color: white;
  border-color: white;
}
.btn-outline-dark:hover {
  color: #212529;
  background-color: white;
}
